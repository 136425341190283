import React from "react";
import styled from "styled-components";
import { Section, SectionBody, Column, H2, H3 } from '../layout';

const Ul = styled.ul`
    padding: 0;
    list-style: none;
    font-size: 1.125rem;
    line-height: 1.5rem;

    > li {
        margin: 0 0 1rem;
    }
`

const SectionWrapper = styled.div`
    background: #fbfbfb;
`

const Services = () => {
    return (
        <SectionWrapper>
            <Section id="services">
                <H2>Services</H2>
                <SectionBody>
                    <Column>
                        <H3>Strategic Advice</H3>
                        <Ul>
                            <li>Considered and practical advice on managing issues arising during approvals processes</li>
                            <li>Assessment of pathways, options and risks with clear recommendations</li>
                            <li>Pragmatic legal advice regarding the application of planning, environment and heritage law</li>
                        </Ul>
                    </Column>
                    <Column>
                        <H3>Planning Litigation</H3>
                        <Ul>
                            <li>Advice on appeal and other dispute resolution options</li>
                            <li>Coordination of litigation teams, including barristers and expert witnesses</li>
                            <li>Representation at VCAT and Planning Panels Victoria</li>
                        </Ul>
                    </Column>
                    <Column>
                        <H3>Legal Drafting</H3>
                        <Ul>
                            <li>Negotiation and drafting of section 173 agreements for a range of matters, including development contributions and affordable housing provision</li>
                            <li>Drafting planning permit conditions, submissions, claims and settlement agreements</li>
                        </Ul>
                    </Column>
                </SectionBody>
            </Section>
        </SectionWrapper>
    )
}

export default Services;
