import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Hero from "../components/hero"
import About from "../components/about"
import Services from "../components/services"
import Articles from "../components/articles"
import Contact from "../components/contact"
import Footer from "../components/footer"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Polis Legal - Planning and environment law specialist</title>
        <meta name="description" content="Legal specialist in Victorian planning and environment law, including strategic advice, planning litigation and legal drafting." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Hero />
      <Services />
      <About />
      <Articles />
      <Contact />
      <Footer />
    </Layout>
  )
}

export default IndexPage
