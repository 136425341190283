import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Section, SectionBody, Column, H2, H3 } from '../layout';

const SectionWrapper = styled.div`
    background: #fbfbfb;
`

const ReadMore = styled(Link)`
    line-height: 1.5rem;
    font-size: 1.125rem;
    color: #2A8236;
`

const Services = () => {
    return (
        <SectionWrapper>
            <Section id="articles">
                <H2>Articles</H2>
                <SectionBody>
                    <Column>
                        <H3>Changes to the Heritage Act 2017 - What you need to know</H3>
                        <p>Recent changes passed by Parliament to the Heritage Act 2017 are due to commence on 1 February 2024, and most notably include a new exclusion determination process for State government infrastructure projects.</p>
                        <ReadMore href="/articles/changes-to-the-heritage-act-2017">
                            Read more
                        </ReadMore>
                    </Column>
                    <Column>
                        <H3>What to expect in 2023</H3>
                        <p>2023 is a year of somewhat stabilising the new 'normal' for how we live, work and appreciate our spaces. Here's a snapshot of some key developments which can be expected for this year.</p>
                        <ReadMore href="/articles/what-to-expect-in-2023">
                            Read more
                        </ReadMore>
                    </Column>
                    <Column>
                        <H3>Planning during the caretaker period </H3>
                        <p>Those who work in the planning and environment sector will know the familiar rush for processes, decisions and approvals to be secured before the caretaker period starts prior to an election. But why? What is the caretaker period exactly and how does it affect planning and environment matters? </p>
                        <ReadMore href="/articles/planning-during-the-caretaker-period">
                            Read more
                        </ReadMore>
                    </Column>
                </SectionBody>
            </Section>
        </SectionWrapper>
    )
}

export default Services;
